import { createGlobalStyle } from "styled-components"

const Global = createGlobalStyle`
    body {
        background-color: #F2F1F3;
        margin: 0;
        /* @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
        font-family: 'Roboto', sans-serif; */
    }
`

export default Global
